require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("bootstrap");
require('./lib/easing/easing.min');
require('./lib/mobile-nav/mobile-nav');
require('./lib/waypoints/waypoints.min');
require('./lib/counterup/counterup.min');
require('./lib/owlcarousel/owl.carousel.min');
require('./lib/lightbox/js/lightbox.min');

const WOW = require('wowjs');
window.wow = new WOW.WOW({
    live: false
});

// Js
import('jquery');
import('popper.js');
import('./main');

import('./lib/animate/animate.min.css');
import('./lib/animate/animate.min.css');
import('./lib/ionicons/css/ionicons.min.css');
import('./lib/owlcarousel/assets/owl.carousel.min.css');
import('./lib/lightbox/css/lightbox.min.css');

import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/css/all";


window.setTimeout(function () {
    $("#notice").fadeTo(500, 0).slideUp(500, function () {
        $(this).remove();
    });
}, 5000);


require("trix");
require("@rails/actiontext");
